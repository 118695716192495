











import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'
import WhiteList from './WhiteList.vue'

@Component({
  components: {
    WhiteList
  }
})
export default class WhiteListModal extends Vue {
  dialog = false
}
