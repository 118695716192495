
















import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch, Prop, Emit } from 'vue-property-decorator'

@Component
export default class WhiteListModal extends Vue {
  testItems: Array<any> = [
    { text: 'WL_VAMOR_01', key: 'WL_VAMOR_01' },
    { text: 'WL_VAMOR_02', key: 'WL_VAMOR_02' }
  ]

  selected = 'WL_VAMOR_01'
  textArea = `EA48DE62AF
154E58A625`

  close () {
    this.$emit('close')
  }
}
