






































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import ChargePointApi from '../../services/ChargePointApi'
import AddChargepointModal from '../../components/chargepoint/AddChargepointModal.vue'
import WhiteListModal from '../../components/whitelist/WhiteListModal.vue'
import downloadExcel, {
  ExcelData,
  ExcelDataHeader,
  Orientations
} from '../../functions/downloadExcel'
import PaginationInterface from '../../typings/PaginationInterface'
import { mapGetters } from 'vuex'
import {
  ChargePoint,
  ConnectorInterface,
  Placement
} from '@/typings/ChargePoint'
import moment from 'moment'
import { GENERIC_FILTER_CHARGERS } from '@/mocks/featureFlags'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import { RelationFilterInterface, RelationTypes } from '@/typings/RelationFilterInterface'

enum States {
  online = 'Online',
  ONLINE = 'ONLINE',
  offline = 'Offline',
  OFFLINE = 'OFFLINE',
  occupied = 'OCCUPIED',
  parking = 'PARKING',
  charging = 'CHARGING',
  empty = ''
}

enum Directions {
  ascending,
  descending
}

@Component({
  components: {
    AddChargepointModal,
    WhiteListModal
  },
  filters: {
    capitalize: function (value: string) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  computed: {
    ...mapGetters({
      userDTA: 'user/userInfo',
      contractDTA: 'chargepoints/contractDTA',
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class Chargepoints extends Vue {
  genericRelationFilter!: RelationFilterInterface
  modeState = process.env.VUE_APP_MODE
  isTable = true
  search = ''
  page = 1
  pageCount = 0
  current = 1
  tmpTab: ChargePoint[] = []
  loading = false
  selected: ChargePoint[] = []
  showMobileMenu = false

  get chargerHeaders() {
    const headersTab = [
      {
        text: this.t('$vuetify.chargepoint.table.chargepoint'),
        value: 'chargeboxidentifier'
      },
      {
        text: this.t('$vuetify.chargepoint.table.chargepointserialnumber'),
        value: 'chargepointserialnumber'
      },
      {
        text: this.t('$vuetify.chargepoint.table.description'),
        value: 'description'
      },
      {
        text: this.t('$vuetify.chargepoint.table.location'),
        value: 'location'
      },
      {
        text: this.t('$vuetify.chargepoint.table.state'),
        value: 'state'
      }
    ]
    // @ts-ignore
    if (this.userDTA && this.userDTA.data.role !== 'User' && this.userDTA.data.Relation.IsCompany === 1) {
      headersTab.splice(
        3,
        0, // Insert at index 3
        {
          text: this.t('$vuetify.chargepoint.table.driver'),
          value: 'landownerdescription'
        },
        {
          text: this.t('$vuetify.chargepoint.table.driverReference'),
          value: 'landownerreference'
        }
      )
    }
    return headersTab
  }

  get chargerExcelHeaders() {
    const headersTab = [
      {
        text: this.t('$vuetify.chargepoint.table.chargepoint'),
        value: 'chargeboxidentifier'
      },
      {
        text: this.t('$vuetify.chargepoint.table.chargepointserialnumber'),
        value: 'chargepointserialnumber'
      },
      {
        text: this.t('$vuetify.chargepoint.table.description'),
        value: 'description'
      },
      {
        text: this.t('$vuetify.chargepoint.table.location'),
        value: 'location'
      },
      {
        text: this.t('$vuetify.chargepoint.table.state'),
        value: 'state'
      },
      {
        text: this.t('$vuetify.chargepoint.table.lastheartbeat'),
        value: 'lastheartbeat'
      },
      {
        text: this.t('$vuetify.chargepoint.table.connectors'),
        value: 'connectors'
      },
      {
        text: this.t('$vuetify.chargepoint.table.errorConnectors'),
        value: 'errorConnectors'
      }
    ]

    // @ts-ignore
    if (this.userDTA && this.userDTA.data.role !== 'User' && this.userDTA.data.Relation.IsCompany === 1) {
      headersTab.splice(
        3,
        0, // Insert at index 3
        {
          text: this.t('$vuetify.chargepoint.table.driver'),
          value: 'landownerdescription'
        },
        {
          text: this.t('$vuetify.chargepoint.table.driverReference'),
          value: 'landownerreference'
        }
      )
    }
    return headersTab
  }

  items = [
    {
      text: 'Home',
      disabled: false,
      href: 'breadcrumbs_dashboard'
    },
    {
      text: 'Administration',
      disabled: false,
      href: 'breadcrumbs_link_1'
    },
    {
      text: 'My charge cards',
      disabled: true,
      href: 'breadcrumbs_link_1'
    }
  ]

  dataTab: ChargePoint[] = []

  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  refresh() {
    this.getChargers()
  }

  toggleMobileMenu() {
    this.showMobileMenu = !this.showMobileMenu
  }

  exportDownload(delimiter: ';') {
    const data: ExcelData = {
      headers: this.head,
      data: this.tableData,
      options: {
        name: 'Tokens',
        exportFileName: 'Charger-List',
        fileExtension: 'xlsx',
        csvDelimiter: delimiter,
        defaultColWidth: 20,
        orientation: Orientations.landscape
      }
    }
    downloadExcel(data)
  }

  getRelativeTime(timestamp: string) {
    return moment(timestamp).fromNow(true)
  }

  getRelativeHourTime(timestamp: string) {
    const now = moment(new Date())
    const end = moment(timestamp)
    const duration = moment.duration(now.diff(end))
    return duration.asHours()
  }

  isCPOffline(state: States) {
    return !(state === States.online || state === States.ONLINE)
  }

  getColorByState(state: States) {
    if (state === States.online || state === States.ONLINE) {
      return States.ONLINE
    }
    return States.OFFLINE
  }

  get tableItems(): ChargePoint[] {
    return this.dataTab
  }

  get tableData() {
    const rows: Array<Array<string | number>> = []
    this.selected = this.tableItems
    for (const element of this.selected) {
      const row: Array<string | number> = []
      row.push(`${element.chargeboxidentifier}`)
      row.push(`${element.chargepointserialnumber}`)
      row.push(`${element.description}`)
      // @ts-ignore
      if (this.userDTA && this.userDTA.data.role !== 'User' && this.userDTA.data.Relation.IsCompany === 1) {
        const landownerDescription = element.landownerdescription ?? 'N/A'
        row.push(landownerDescription)
        const landownerReference = element.landownerreference ?? 'N/A'
        row.push(landownerReference)
      }
      row.push(this.address(element.placements))
      row.push(element.state)
      const lastheartbeat = element.lastheartbeat
      const formattedLastHeartbeat = lastheartbeat
        ? this.getRelativeHourTime(lastheartbeat)
        : 'N/A'
      row.push(formattedLastHeartbeat)
      row.push(element.connectors.length)
      row.push(this.getConnectorError(element.connectors))
      rows.push(row)
    }
    return rows
  }

  get head() {
    const rtn: Array<ExcelDataHeader> = []
    for (const element of this.chargerExcelHeaders) {
      rtn.push({
        name: `${element.text}`,
        totalsRowLabel: 'sum:',
        filterButton: true
      })
    }
    return rtn
  }

  next() {
    this.current++
  }

  getStateConnector(connector: ConnectorInterface, chargerState: States) {
    if (
      chargerState === States.offline ||
      chargerState === States.OFFLINE ||
      chargerState === States.empty
    ) {
      return States.OFFLINE
    }

    if (connector.state === States.occupied) {
      if (this._.isEmpty(connector.sessionstate)) {
        return States.parking
      }
      return connector.sessionstate
    }

    if (
      this._.isEmpty(connector.state) &&
      this._.isEmpty(connector.sessionstate)
    ) {
      return States.parking
    }

    return connector.state
  }

  getConnectorError(connectorTab: ConnectorInterface[]) {
    let cpt = 0
    for (const element of connectorTab) {
      if (element.state === 'UNKNOWN') {
        cpt++
      }
    }
    return cpt
  }

  address(placements: Placement[]) {
    if (placements.length > 0) {
      return placements[placements.length - 1].address
    }
    return ''
  }

  async getDetails(data: ChargePoint) {
    localStorage.setItem('currentPage', this.page + '') // Utilisez localStorage ou sessionStorage en fonction de vos besoins
    this.$router.push({
      name: 'navchargepointsdetails',
      params: { id: data.id }
    })
  }

  mapDataForTable(data: ChargePoint[]): ChargePoint[] {
    return data.map((item: ChargePoint) => {
      let address = ''
      if (item.placements.length > 0 && item.placements[0].address) {
        address = item.placements[0].address
      }
      return {
        id: item.id,
        key: item.key,
        location: address,
        description: item.description,
        chargepointserialnumber: item.chargepointserialnumber,
        chargeboxidentifier: item.chargeboxidentifier,
        connectors: item.connectors,
        placements: item.placements,
        landownerdescription: item.landownerdescription,
        landownerreference: item.landownerreference,
        state: item.state,

        lastheartbeat: item.lastheartbeat
      }
    })
  }

  tableSorting(
    items: ChargePoint[],
    index: Array<keyof ChargePoint>,
    isDescending: boolean[]
  ) {
    items.sort((firstItem: ChargePoint, secondItem: ChargePoint) => {
      if (index.includes('state')) {
        return this.handleStateSorting(isDescending, firstItem, secondItem)
      } else {
        const valueExists =
          firstItem &&
          index.length > 0 &&
          index[0] &&
          firstItem[index[0]] &&
          secondItem &&
          secondItem[index[0]]

        if (valueExists) {
          if (!isDescending[0]) {
            // These TS errors make no sense because we have a check in place (valueExists)
            // @ts-ignore
            return firstItem[index[0]] < secondItem[index[0]] ? -1 : 1
          } else {
            // @ts-ignore
            return secondItem[index[0]] < firstItem[index[0]] ? -1 : 1
          }
        }
        return 0
      }
    })

    return items
  }

  private handleStateSorting(
    isDescending: boolean[],
    firstItem: ChargePoint,
    secondItem: ChargePoint
  ) {
    if (isDescending.includes(true)) {
      return this.handleSorting(
        firstItem.lastheartbeat || '',
        secondItem.lastheartbeat || '',
        Directions.descending
      )
    } else {
      return this.handleSorting(
        firstItem.lastheartbeat || '',
        secondItem.lastheartbeat || '',
        Directions.ascending
      )
    }
  }

  handleSorting(
    item1: string,
    item2: string,
    direction = Directions.ascending
  ) {
    if (direction === Directions.ascending && item1 && item2) {
      const item1AfterItem2 = moment(item1).isAfter(moment(item2))
      if (item1AfterItem2) {
        return 1
      } else {
        return -1
      }
    } else if (direction === Directions.descending) {
      const item1AfterItem2 = moment(item1).isAfter(moment(item2))
      if (item1 && item2 && item1AfterItem2) {
        return -1
      } else {
        return 1
      }
    }
    return 0
  }

  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
  }

  async getChargers() {
    try {
      const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/chargepoints',
        flag: GENERIC_FILTER_CHARGERS
      })
      let relationContactId
      let relationId
      if (isAllowed) {
        const selectedRelation = this.genericRelationFilter
        if (
          selectedRelation &&
          selectedRelation.Type === RelationTypes.relationContact
        ) {
          relationContactId = selectedRelation.Id
        } else {
          relationId = selectedRelation.Id
        }
      }
      this.loading = true
      const data = (await new ChargePointApi().myChargers(relationId, relationContactId)) as any
      this.dataTab = this.mapDataForTable(data.data)
      this.tmpTab = data.data
      const storedCurrentPage = localStorage.getItem('currentPage')
      if (storedCurrentPage) {
        this.page = parseInt(storedCurrentPage)
      }
      this.loading = false
    } catch (error) {
      console.log(error)
    }
  }

  handleBeforeUnload() {
    localStorage.setItem('currentPage', '1')
  }

  get height() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return 220
      case 'sm':
        return 400
      case 'md':
        return 500
      case 'lg':
        return 600
      case 'xl':
        return 800
    }
  }

  @Watch('pagination', { deep: true })
  paginationOnChanged(val: PaginationInterface, oldVal: PaginationInterface) {
    if (
      val.pagesize !== oldVal.pagesize ||
      val.pagenumber !== oldVal.pagenumber
    ) {
      this.getChargers()
    }
  }

  @Watch('search')
  doSearch(val: string) {
    if (!this.isTable) {
      if (!this._.isEmpty(val)) {
        this.dataTab = this.tmpTab.filter(function (e: ChargePoint) {
          return (
            e.description.includes(val) ||
            (e.ownerdesc && e.ownerdesc.includes(val)) ||
            e.chargepointserialnumber.includes(val) ||
            e.chargeboxidentifier.includes(val)
          )
        })
      } else {
        this.dataTab = this.tmpTab
      }
    }
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged() {
    this.getChargers()
  }
}
